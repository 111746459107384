import MainTable from "./components/MainTable";
import { useIsAuthenticated } from "@azure/msal-react";
import Login from "./components/Login";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
/>;
function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <div className="App">
        <main className="p-4">
          {isAuthenticated ? <MainTable /> : <Login />}
        </main>
      </div>
    </>
  );
}

export default App;
