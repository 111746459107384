import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { RowData } from "../interfaces/contracts";

interface TableHeaderProps {
  columns: Record<string, string>;
  sortConfig: {
    key: keyof RowData | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof RowData) => void;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  sortConfig,
  handleSort,
}) => {
  return (
    <thead className="d-none d-lg-table-row">
      <Row>
        {Object.keys(columns).map((columnKey) => (
          <Col
            lg={true}
            key={columnKey}
            sortDirection={
              sortConfig.key === columnKey ? sortConfig.direction : false
            }
            className="table-pagination bg-slate-100 text-right"
            onClick={() => handleSort(columnKey as keyof RowData)}
          >
            {columns[columnKey as keyof typeof columns]}
            &nbsp; &nbsp;
            <FontAwesomeIcon icon={faSort} />
          </Col>
        ))}
      </Row>
    </thead>
  );
};

export default TableHeader;
