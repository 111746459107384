import { fetchData, postData } from "../services/api";
import StatusBusyModal from "./StatusBusyModal";
import Table from "react-bootstrap/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../assets/TableStyle.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import {
  CareManager,
  RowData,
  Clinician,
  FilterOptions,
} from "../interfaces/contracts";
import { apiBaseUrl, columns } from "./constants.js";
import { PaginationComponent } from "./PaginationComponent";
import { formattedDate, getValueForClientStatusColumn } from "./helper";
import { CircularProgress } from "./CircularProgress";
import AppNavbar from "./AppNavbar";
import TableHeader from "./TableHeader";
import SelectFilter from "./SelectFilter";

const MainTable: React.FC = () => {
  const [rows, setRows] = useState<RowData[]>([]);
  const [filteredRows, setFilteredRows] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [selectedView, setSelectedView] = useState<string>("client"); // Default to 'all' for All option
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [sortConfig, setSortConfig] = useState<{
    key: keyof RowData | null;
    direction: "asc" | "desc";
  }>({
    key: null,
    direction: "desc",
  });

  // Filter states
  const [selectedCareManager, setSelectedCareManager] = useState<{
    key: string;
    value: string;
    name: string;
  }>({ key: "careManagerId", value: "", name: "All" });

  const [selectedClinician, setSelectedClinician] = useState<{
    key: string;
    value: string;
    name: string;
  }>({ key: "clinicianId", value: "", name: "All" });

  const [clinicians, setClinicians] = useState<Clinician[]>([
    { key: "clinicianId", value: "", name: "All" },
  ]);

  const [careManagers, setCareManagers] = useState<CareManager[]>([
    { key: "careManagerId", value: "", name: "All" },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, rows.length);
  const currentRows = filteredRows.slice(startIndex, endIndex);
  const [showModal, setShowModal] = useState(false);
  const [rowDataForModal, setRowDataForModal] = useState({} as RowData);

  const handleOpenModal = (showModal: boolean, row?: RowData) => {
    setShowModal(showModal);
    setRowDataForModal(row || ({} as RowData));
  };

  const handleRowDataLog = useCallback(() => {
    console.log({ rowDataForModal });
  }, [rowDataForModal]);

  useEffect(() => {
    handleRowDataLog();
  }, [handleRowDataLog, rowDataForModal]);

  if (!apiBaseUrl) {
    console.error(
      "API base URL is not defined. Please check your environment variables."
    );
  } else {
    console.log("API base URL:", apiBaseUrl);
  }

  //cache get data
  const cachedData = useMemo(() => {
    if (!authToken) {
      return Promise.resolve([]);
    }

    return Promise.all([
      fetchData(apiBaseUrl, selectedView),
      fetch(`${apiBaseUrl}/api/v1/Clinician`, {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      }).then((response) => response.json()),
      fetch(`${apiBaseUrl}/api/v1/CareManager`).then((response) =>
        response.json()
      ),
    ]);
  }, [selectedView, authToken]);

  const getLoadData = useCallback(async () => {
    setLoading(true);
    console.log(selectedView);
    const [rowsData, cliniciansData, careManagersData] = await cachedData;
    if (Array.isArray(rowsData)) {
      setRows(rowsData);
      setFilteredRows(rowsData);
    } else {
      console.error("Expected an array but got:", rows);
    }

    if (Array.isArray(cliniciansData)) {
      const formattedClinicians = cliniciansData.map((clinician) => ({
        key: clinician.clinicianId,
        value: clinician.fullName,
        name: clinician.fullName,
      }));
      setClinicians([
        { key: "clinicianId", value: "", name: "All" },
        ...formattedClinicians,
      ]);
    } else {
      console.error("Expected an array but got:", cliniciansData);
    }
    if (Array.isArray(careManagersData)) {
      const formattedCareManagers = careManagersData.map((careManager) => ({
        key: careManager.careManagerId,
        value: careManager.fullName,
        name: careManager.fullName,
      }));
      setCareManagers([
        { key: "careManagerId", value: "", name: "All" },
        ...formattedCareManagers,
      ]);
    } else {
      console.error("Expected an array but got:", careManagersData);
    }

    setLoading(false);
  }, [cachedData]);

  useEffect(() => {
    getLoadData();
  }, [getLoadData]);

  useEffect(() => {
    if (accounts.length > 0) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          setAuthToken(response.accessToken);
        });
    }
  }, [accounts, instance]);

  const handleSort = (column: keyof RowData) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedRows = [...filteredRows].sort((a, b) => {
      if (a[column] < b[column]) return direction === "asc" ? -1 : 1;
      if (a[column] > b[column]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key: column, direction });
    setFilteredRows(sortedRows);
  };

  const handleViewChange = (view: string) => {
    setSelectedView(view);
    const filter = createFilterOptions(selectedCareManager, selectedClinician);
    filter.filterClause?.length > 0
      ? makeApiCall(
          view,
          JSON.stringify(filter),
          setFilteredRows,
          setTableLoading
        )
      : getLoadData(); // if filterclause there then do a POST with filter as body else just do a simple get like done on page load
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  const createFilterOptions = (
    careManager: CareManager,
    clinician: Clinician
  ): FilterOptions => {
    const filterOptions: FilterOptions = {
      filterClause: [],
    };
    const careManagerFilter = {
      key: "careManagerId",
      value: careManager.key.toString(),
    };

    const clinicianFilter = {
      key: "clinicianId",
      value: clinician?.key.toString(),
    };
    if (careManager && careManager.value.length > 0) {
      filterOptions.filterClause.push(careManagerFilter);
    }
    if (clinician && clinician.value.length > 0) {
      filterOptions.filterClause.push(clinicianFilter);
    }

    return { filterClause: filterOptions.filterClause };
  };

  const handleCareManagerSelect = (e: any) => {
    const selectedValue = e.target.value as string;
    const careManager = careManagers.find(
      (cm) => cm.value === selectedValue
    ) || { key: "careManagerId", value: "", name: "All" };
    if (careManager) setSelectedCareManager(careManager);
    const filter = createFilterOptions(careManager, selectedClinician);
    filter.filterClause?.length > 0
      ? makeApiCall(
          selectedView,
          JSON.stringify(filter),
          setFilteredRows,
          setTableLoading
        )
      : getLoadData(); // if filterclause there then do a POST with filter as body else just do a simple get like done on page load
  };

  const handleClinicianSelect = (e: any) => {
    {
      const selectedValue = e.target.value as string;
      const clinician = clinicians.find((cl) => cl.value === selectedValue) || {
        key: "clinicianId",
        value: "",
        name: "All",
      };
      if (clinician) setSelectedClinician(clinician);
      const filter = createFilterOptions(selectedCareManager, clinician);
      filter.filterClause?.length > 0
        ? makeApiCall(
            selectedView,
            JSON.stringify(filter),
            setFilteredRows,
            setTableLoading
          )
        : getLoadData(); // if filterclause there then do a POST with filter as body else just do a simple get like done on page load
    }
  };

  return (
    <div>
      <AppNavbar
        selectedView={selectedView}
        handleViewChange={handleViewChange}
        visible={false}  
      />
      <Container fluid style={{ marginTop: "10px" }}>
        <div>
          <Row>
            <Col md={2}>
              <SelectFilter
                id="careManagerSelect"
                label="Filter by Care Manager"
                value={selectedCareManager.value}
                options={careManagers}
                onChange={handleCareManagerSelect}
              />
            </Col>
            <Col md={2}>
              <SelectFilter
                id="clinicianSelect"
                label="Filter by Clinician"
                value={selectedClinician.value}
                options={clinicians}
                onChange={handleClinicianSelect}
              />
            </Col>
          </Row>
        </div>
        <StatusBusyModal
          showModal={showModal}
          rowData={rowDataForModal}
          selectedView={selectedView}
          handleOpenModal={handleOpenModal}
          authToken={authToken ? authToken : ""}
        />

        {tableLoading ? (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress />
          </div>
        ) : (
          <Table striped={true} bordered hover size="sm">
            <TableHeader
              columns={columns}
              sortConfig={sortConfig}
              handleSort={handleSort}
            />
            <tbody>
              {currentRows.map((row, i) => (
                <Row style={{ border: "0px" }} key={i}>
                  {/* border 0 applied to row and borderColor f0f0f0 applied to Col */}
                  {Object.keys(columns).map((columnKey) => (
                    <Col lg={true} style={getCustomStyle(i)}>
                      <div className="d-flex align-items-center">
                        <div className="d-md-block d-lg-none  ">
                          <b>{columns[columnKey as keyof typeof columns]}</b>:
                        </div>
                        <div className="wrap-text">
                          {getDataForColumn(row, columnKey, (showModal) =>
                            handleOpenModal(showModal, row)
                          )}{" "}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </tbody>
            <PaginationComponent
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              totalRows={rows.length}
            ></PaginationComponent>
          </Table>
        )}
      </Container>
    </div>
  );
};

export default MainTable;

const getDataForColumn = (
  row: RowData,
  columnKey: string,
  handleOpenModal: (showModal: boolean) => void
) => {
  let content;
  const cellValue = row[columnKey as keyof RowData]; // Extracted variable
  if (columnKey === "appointmentDateTime") {
    content = formattedDate(cellValue);
  } else if (columnKey === "appointmentDurationInMins") {
    content = cellValue.toString() + " Mins";
  } else if (columnKey === "clientStatus") {
    content = getValueForClientStatusColumn(handleOpenModal, cellValue);
  } else {
    content = cellValue;
  }
  return content;
};

const getCustomStyle = (indx: number) => {
  return {
    backgroundColor: indx % 2 === 0 ? "white" : "#f9f9f9",
    borderColor: "#f0f0f0",
  };
};

function makeApiCall(
  selectedView: string,
  filter: string,
  setFilteredRows: React.Dispatch<React.SetStateAction<RowData[]>>,
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  setTableLoading(true);
  Promise.all([postData(apiBaseUrl, selectedView, filter)]).then(([data]) => {
    if (Array.isArray(data)) {
      setFilteredRows(data);
      setTableLoading(false);
    } else {
      console.error("Expected an array but got:", data);
      setTableLoading(false); // Set loading state for the Table
    }
  });
}
