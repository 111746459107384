import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { CallGetResponse, RowData } from "../interfaces/contracts";
import { apiBaseUrl, NUMBER_OF_ATTEMPTS } from "./constants.js";
interface MyComponentProps {
  showModal: boolean;
  rowData: RowData;
  selectedView: string;
  handleOpenModal: (showModal: boolean) => void;
  authToken: string;
}
const onLoadPostCall = async (
  selectedView: string,
  eventId: string,
  callerId: string,
  authToken: string
): Promise<CallGetResponse> => {
  let apiUrl = getApiUrl(apiBaseUrl, selectedView);
  const bodyParam = {
    requestType: selectedView,
    requestId: selectedView.toString().toUpperCase() == "CLIENT"?callerId.toString():eventId.toString(),
  };
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + authToken,
      },
      body: JSON.stringify(bodyParam),
    });
    return await response.json();
  } catch (error) {
    console.error(`Error fetching data for ${selectedView}:`, error);
    throw error;
  }
};
const StatusBusyModal: React.FC<MyComponentProps> = ({
  showModal,
  rowData,
  selectedView,
  handleOpenModal,
  authToken,
}) => {
  const defaultCallGetResponse: CallGetResponse = {
    callOwner: "",
    callerId: "",
    status: "",
    statusLog: [],
    meetingUrl: "",
    callId: 0,
  };

  const [data, setData] = useState<CallGetResponse>(defaultCallGetResponse);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error message
  const [meetingUrl, setmeetingUrl] = useState<string | null>(null); 
  useEffect(() => {
    setData(defaultCallGetResponse);
  }, []);

  useEffect(() => {
    if (showModal) {
      setData(defaultCallGetResponse); // Reset data when modal opens
      let counter: number = 0;
      const fetchData = async () => {
        try {
          const response = await onLoadPostCall(
            selectedView,
            rowData.eventId.toString(),
            rowData.clientId.toString(),
            authToken
          );
          setData(response);

          const callId = response.callId; // Extract callId from response
          const baseurl = `${apiBaseUrl}/api/v1/call/${callId}`; // Use the imported constant
          const intervalId = setInterval(async () => {
            const statusResponse = await fetch(baseurl, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${authToken}`, // Pass authToken in the headers
              },
            });
            const statusData: CallGetResponse = await statusResponse.json();
            setData(statusData);
            if (statusData.status === "DeviceInformed") {
              const url = statusData?.meetingUrl;
              // Check if url is not null
              setmeetingUrl(url);
              window.open(url, "_blank")?.focus();
              clearInterval(intervalId); // Stop fetching when status is 'Complete'
            }
             else if (statusData.status === "ConsentRejected")
             {  
                clearInterval(intervalId); // Stop fetching when status is 'Complete'
                setErrorMessage(rowData.name + " has rejected the call, Please try again later"); // Set error message
             }
             else
            {
              if (counter >= NUMBER_OF_ATTEMPTS) {
                clearInterval(intervalId); // Stop fetching when status is 'Complete'
                setErrorMessage(rowData.name + " is not available, Please try again later"); // Set error message
              } else {
                counter = counter + 1;
              }
            }
          }, 1000 * 5); // 5 sec
        } catch (error) {
          console.error(`Error fetching data for ${selectedView}:`, error);
          throw error;
        }
      };

      fetchData();
    }
  }, [showModal]);

  const notifyAPIModalClosed = async () => {
    const callId = data.callId;
    const baseurl = `${apiBaseUrl}/api/v1/call/${callId}`; // Use the imported constant
    await fetch(baseurl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass authToken in the headers
      },
    });
    setErrorMessage(null); // Clear error message when closing modal
    handleOpenModal(false);
  };
  const openMeeitngURl = async () => {
    window.open(data.meetingUrl, "_blank")?.focus();
  };

  return (
    <div className="container">
      {/* Modal component */}
      {showModal && (
        <Modal show={showModal} className="modal">
          <Modal.Header closeButton onHide={notifyAPIModalClosed}>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
             
              <h4>Calling : {rowData.name}</h4>
              <p>
                Appointment Type:{" "}
                {selectedView === "client"
                  ? "Adhoc Meeting"
                  : rowData.appointmentType}{" "}
              </p>
              <p>Phone: {rowData.clientMobileNumber}</p>
              <p>
                {data &&
                  data.statusLog &&
                  data.statusLog.map((status) => <p> {status} ....</p>)}
              </p>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  <i className="fas fa-exclamation-circle me-2"></i>
                  {errorMessage}
                </div>
              )}
            </>
          </Modal.Body>

          <Modal.Footer>
            {data.meetingUrl && (
                 <Button variant="primary" onClick={openMeeitngURl}>
                    Join
                 </Button>
              )}
           
            <Button variant="secondary" onClick={notifyAPIModalClosed}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

function getApiUrl(apiBaseUrl: string, selectedView: string) {
  let apiUrl = `${apiBaseUrl}/api/v1/CALL`;
  // Determine the API URL based on selected view
  return apiUrl;
}

export default StatusBusyModal;
