import Button from "react-bootstrap/Button";

interface CustomButtonProps {
  onClick?: () => void;
  variant: "primary" | "success" | "danger" | "secondary";
  buttonText: string | number;
  disabledFlag: boolean;
}
export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  variant,
  buttonText,
  disabledFlag,
}) => {
  return (
    <Button
      variant={variant}
      disabled={disabledFlag}
      onClick={onClick ? onClick : undefined}
      style={{
        borderRadius: "20px", // Makes the button oval
        padding: "10px 20px",
        width: "8em", // Set width to 90% to occupy more space
        display: "block", // Ensures the button behaves like a block element
        marginLeft: "10px", // Centers the button within the column
      }}
    >
      {buttonText}
    </Button>
  );
};
