export const PaginationComponent = ({
  currentPage,
  setCurrentPage,
  rowsPerPage,
  totalRows,
}: {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  rowsPerPage: number;
  totalRows: number;
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div>
      <ul className="pagination">
        {pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item${
              pageNumber === currentPage ? " active" : ""
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            <span className="page-link">{pageNumber}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaginationComponent;
