// Constants.ts

export const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

export const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
export const apiBaseUrl = "https://api.cognitivehealth.com.au";
//export const apiBaseUrl = "https://comchsapiuat.azurewebsites.net";
//export const apiBaseUrl = "https://localhost:7099";
export const NUMBER_OF_ATTEMPTS = 12;// 12 * 5 seconds/ attempts for 1 minute

export const columns = {
  clientStatus: "Call",
  name: "Name",
  clientMobileNumber: "Phone #",
  careManagerFullName: "Care Manager",
  careManagerMobileNumber: "Care Manager #",
  appointmentDateTime: "Last Appt",
  appointmentType: "Last Appt Type",
  appointmentDurationInMins: "Appt Duration",
  clinicianFullName: "Clinician",
  comment: "Notes",
};
export const clientStatusOptions = {
  BUSY: "BUSY",
  CALL: "CALL",
  UNAVAILABLE: "UNAVAILABLE",
  UPCOMING: "UPCOMING",
};
