export const fetchData = async (
  apiBaseUrl: string,
  selectedView: string
): Promise<any> => {
  let apiUrl = getApiUrl(apiBaseUrl, selectedView);

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching data for ${selectedView}:`, error);
    throw error;
  }
};

export const postData = async (
  apiBaseUrl: string,
  selectedView: string,
  bodyParam: string
): Promise<any> => {
  let apiUrl = getApiUrl(apiBaseUrl, selectedView);
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyParam,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching data for ${selectedView}:`, error);
    throw error;
  }
};
function getApiUrl(apiBaseUrl: string, selectedView: string) {
  let apiUrl = `${apiBaseUrl}/api/v1/Grid`;
  // Determine the API URL based on selected view
  switch (selectedView) {
    case "schedule":
      apiUrl += "/schedule";
      break;
    case "group":
      apiUrl += "/group";
      break;
    case "client":
      apiUrl += "/client";
      break;
    default:
      apiUrl += ""; // For 'all' or default case
      break;
  }
  return apiUrl;
}
