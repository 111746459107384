import { CustomButton } from "./CustomButton";

export const formattedDate = (rowDate: string | number) => {
  const date = new Date(rowDate.toLocaleString());
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "short",
    hour12: false,
  });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day} ${month} ${year}, ${hours}:${minutes}`;
};

export const getValueForClientStatusColumn = (
  handleOpenModal: (showModal: boolean) => void,
  cellValue: string | number
) => {
  if (cellValue.toString().toUpperCase() == "CALL") {
    return (
      <CustomButton
        onClick={() => handleOpenModal(true)} // onClick handler
        variant="success" // Button variant for success
        buttonText={cellValue} // Button text
        disabledFlag={false}
      />
    );
  } else if (cellValue.toString().toUpperCase() == "BUSY") {
    return (
      <CustomButton
        variant="danger" // Grey button variant
        buttonText={cellValue} // Button text
        disabledFlag={true}
      />
    );
  } 
  else if (cellValue.toString().toUpperCase() == "JOIN") {
    return (
      <CustomButton
        onClick={() => handleOpenModal(true)} // onClick handler
        variant="primary" // Grey button variant
        buttonText={cellValue} // Button text
        disabledFlag={false}
      />
    );
  } 
  else {
    return (
      <CustomButton
        variant="secondary" // Grey button variant
        buttonText={cellValue} // Button text
        disabledFlag={false}
      />
    );
  }
};
