import React from "react";
import { CareManager, Clinician } from "../interfaces/contracts";

interface SelectFilterProps<T> {
  id: string;
  label: string;
  value: string;
  options: T[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectFilter = <T extends { value: string; name: string }>({
  id,
  label,
  value,
  options,
  onChange,
}: SelectFilterProps<T>) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select id={id} value={value} onChange={onChange} className="form-select">
        <option value="">Choose...</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectFilter;
